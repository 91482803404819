<template>
  <v-form ref="form">
    <v-row class="mx-0 d-flex justify-space-between align-center">
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          :name="'As of date'"
        >
          <input-template-date
            v-model="asOfDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :input-templ="{label: 'As of date', hideOptionalText: true}"
            :mode="'noComments'"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col v-if="!isHolder">
        <common-input-checkbox
          v-model="internalValue.include_draft"
          label="Include Drafts"
        />
      </v-col>
      <v-col v-if="isSummaryView">
        <common-input-checkbox
          v-model="internalValue.include_financingRounds"
          label="Show Financing Rounds"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'

  export default {
    name: 'CaptableHeaderItems',
    components: {
      InputTemplateDate,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      isSummaryView: {
        type: Boolean,
        default: false,
      },
      isHolder: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      date: '',
      search: '',
      asOfDate: '',
    }),
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
    watch: {
      value: {
        handler (val) {
          if (val) {
            this.asOfDate = this.internalValue.date
              ? moment(this.internalValue.date).format('MM/DD/YYYY')
              : moment(new Date()).format('MM/DD/YYYY')
          }
        },
        immediate: true,
      },
      asOfDate: {
        handler (val) {
          if (val && val.length === 10) {
            this.internalValue = { ...this.internalValue, date: moment(val).format('YYYY-MM-DD') }
          } else {
            this.internalValue = { ...this.internalValue, date: '' }
          }
        },
        immediate: true,
      },
    },
  }
</script>
