<template>
  <div>
    <!-- Loader -->
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    />
    <!-- Filters -->
    <captable-filters
      v-model="captableFilters"
      :is-summary-view="false"
      :is-holder="true"
      @input="updateAsofDate"
    />
    <!-- Captable -->
    <div
      v-for="(termData, key) of holderShareTerms"
      :key="key"
      class="mt-4"
    >
      <div
        v-if="(termData.shareList && termData.shareList.length > 0) || (termData.optionList && termData.optionList.length > 0) || (termData.convertibleList && termData.convertibleList.length > 0)"
        class="trsText--text text-h4 font-weight-medium"
      >
        {{ termData.title }}
      </div>
      <!-- Shares & Warrants -->
      <common-trs-data-table
        v-if="termData.shareList && termData.shareList.length > 0"
        :headers="summaryViewHeaders"
        :items="termData.shareList"
        class="trs-table-container-card"
        hide-default-footer
        disable-pagination
        item-key="id"
        show-expand
        :expanded.sync="termData.expanded"
      >
        <!-- Expanded Section -->
        <template v-slot:expanded-item="{headers, item}">
          <td
            :colspan="headers.length"
          >
            <!-- Shares List -->
            <v-row
              v-for="(obj) in item.shares"
              :key="obj.id"
              class="trsText--text text-body-2 text-right"
              @click="navigateToDetailsPage(item.id, item.type)"
            >
              <v-col
                cols="4"
                class="text-left pl-10"
              >
                <share-term-badge
                  :type="item.type"
                  :show-single="true"
                  class="display-inline"
                />
                <span>{{ item.name }}</span>
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ $number(obj.number_outstanding) }}
              </v-col>
              <v-col
                cols="2"
              >
                {{ (obj.ratio_outstanding*100).toFixed(2) }}%
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ $number(obj.number_fully_diluted) }}
              </v-col>
              <v-col
                cols="2"
                class="pr-4"
              >
                {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
              </v-col>
            </v-row>
            <!-- Warrants List -->
            <v-row
              v-for="(obj) in item.warrants"
              :key="obj.id"
              class="trsText--text text-body-2 text-right"
              @click="navigateToDetailsPage(item.warrant_terms_id, CONSTS.SHARE_TERM_TYPES.WARRANT)"
            >
              <v-col
                cols="4"
                class="text-left pl-10"
              >
                <share-term-badge
                  :type="CONSTS.SHARE_TERM_TYPES.WARRANT"
                  :show-single="true"
                  class="display-inline"
                />
                <span>{{ item.warrant_name }}</span>
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                NA
              </v-col>
              <v-col
                cols="2"
              >
                NA
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ $number(obj.number_fully_diluted) }}
              </v-col>
              <v-col
                cols="2"
                class="pr-4"
              >
                {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
              </v-col>
            </v-row>
          </td>
        </template>
        <!-- Total Row -->
        <template v-slot:[`body.append`]="{ headers, items }">
          <tr>
            <td
              :colspan="headers.length"
              class="px-2 footer-bg"
            >
              <v-row
                class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
              >
                <v-col
                  cols="4"
                  class="text-left pl-12"
                >
                  Total
                </v-col>
                <v-col
                  cols="2"
                >
                  <span class="mr-m2">
                    {{ $number(parseFloat(calculateShareSum(items, 'number_outstanding'))) }}
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-2"
                >
                  <span>
                    {{ (calculateShareSum(items, 'ratio_outstanding') * 100).toFixed(2) }} %
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-0"
                >
                  <span class="mr-m2">
                    {{ $number(parseFloat(calculateShareSum(items, 'number_fully_diluted'))) }}
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-2"
                >
                  <span>
                    {{ (calculateShareSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </common-trs-data-table>
      <!-- Convertible Section -->
      <common-trs-data-table
        v-if="termData.convertibleList && termData.convertibleList.length > 0"
        :headers="convertibleShareHeader"
        :items="termData.convertibleList"
        show-expand
        class="trs-table-container-card pa-5"
        hide-default-footer
        :expanded.sync="convertiblesExpanded"
        disable-pagination
        item-key="id"
      >
        <!-- Headers -->
        <template v-slot:[`header.name`]="{ header }">
          <span class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</span>
        </template>
        <template v-slot:[`header.maturity`]="{ header }">
          <span class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</span>
        </template>
        <template v-slot:[`header.principal`]="{ header }">
          <span>
            <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>
        <template v-slot:[`header.interest`]="{ header }">
          <span>
            <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>
        <template v-slot:[`header.total`]="{ header }">
          <span>
            <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>

        <!-- Body -->
        <template v-slot:[`item.principal`]="{item}">
          <span class="trsText--text font-weight-medium text-body-1">
            {{ $currencyWithPrecision(getAmountInThousands(parseFloat(item.principal))) }}
          </span>
        </template>
        <template v-slot:[`item.interest`]="{item}">
          <span class="trsText--text font-weight-medium text-body-1">
            {{ $currencyWithPrecision(getAmountInThousands(parseFloat(item.interest))) }}
          </span>
        </template>
        <template v-slot:[`item.total`]="{item}">
          <span
            class="trsText--text font-weight-medium text-body-1"
          >
            {{ $currencyWithPrecision(getAmountInThousands(parseFloat(item.total))) }}
          </span>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
            class="inner-table"
          >
            <v-row
              v-for="(obj,key) in item.convertibles"
              :key="key"
              class="trsText--text text-body-2 text-right"
              @click="navigateToDetailsPage(item.id, CONSTS.SHARE_TERM_TYPES.CONVERTIBLE, obj.holder)"
            >
              <v-col
                offset="1"
                cols="3"
                class="text-left"
              >
                <span>
                  <share-term-badge
                    :type="item.type"
                    :show-single="true"
                    class="display-inline"
                  />
                </span>
                <span>{{ item.name }}</span>
              </v-col>
              <v-col
                cols="2"
                class="text-center"
              >
                {{ $date(obj.matures_on) }}
              </v-col>
              <v-col
                cols="2"
                class="pr-6"
              >
                {{ $currencyWithPrecision(getAmountInThousands(obj.principal)) }}
              </v-col>
              <v-col
                cols="2"
                class="pr-5"
              >
                {{ $currencyWithPrecision(getAmountInThousands(obj.interest)) }}
              </v-col>
              <v-col
                cols="2"
              >
                {{ $currencyWithPrecision(getAmountInThousands(obj.total)) }}
              </v-col>
            </v-row>
          </td>
        </template>
        <!-- Total Row -->
        <template v-slot:[`body.append`]="{headers, items}">
          <tr>
            <td
              :colspan="headers.length"
              class="px-2 footer-bg"
            >
              <v-row
                class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
              >
                <v-col
                  cols="4"
                  class="text-left pl-12"
                >
                  Total
                </v-col>
                <v-col
                  cols="2"
                />
                <v-col
                  cols="2"
                  class="pr-7"
                >
                  <span>
                    {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(items, 'principal', true))) }}
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-5"
                >
                  <span>
                    {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(items, 'interest', true))) }}
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-2"
                >
                  <span>
                    {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(items, 'total', true))) }}
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </common-trs-data-table>
      <!-- EIP Section -->
      <common-trs-data-table
        v-if="termData.optionList && termData.optionList.length > 0"
        :headers="summaryViewHeaders"
        :items="termData.optionList"
        show-expand
        class="trs-table-container-card pa-5"
        hide-default-footer
        :expanded.sync="eipExpanded"
        disable-pagination
      >
        <template v-slot:[`item.number_outstanding`]="{}">
          <span class="trsText--text font-weight-medium text-body-1">
            NA
          </span>
        </template>
        <template v-slot:[`item.ratio_outstanding`]="{}">
          <span class="trsText--text font-weight-medium text-body-1">
            NA
          </span>
        </template>
        <template v-slot:[`item.number_fully_diluted`]="{item}">
          <span class="trsText--text font-weight-medium text-body-1">
            {{ $number(item.number_fully_diluted) }}
          </span>
        </template>
        <template v-slot:[`item.ratio_fully_diluted`]="{item}">
          <span class="trsText--text font-weight-medium text-body-1">
            {{ (parseFloat(item.ratio_fully_diluted) * 100 ).toFixed(2) }} %
          </span>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
            class="inner-table"
          >
            <v-row
              v-for="(option,key) in item.options"
              :key="key"
              class="trsText--text text-body-2 text-right"
            >
              <v-col
                offset="1"
                cols="3"
                class="text-left"
                @click="navigateToDetailsPage(item.id, CONSTS.SHARE_TERM_TYPES.OPTION)"
              >
                <share-term-badge
                  :type="CONSTS.OPTIONS_TYPE.ISO"
                  :show-single="true"
                  class="display-inline"
                />
                {{ item.name }}
              </v-col>
              <v-col
                cols="2"
              >
                NA
              </v-col>
              <v-col cols="2">
                NA
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ $number(option.number_fully_diluted) }}
              </v-col>
              <v-col
                cols="2"
              >
                {{ (parseFloat(option.ratio_fully_diluted)*100).toFixed(2) }}%
              </v-col>
            </v-row>
            <v-row
              class="trsText--text text-body-2 text-right font-weight-bold"
            >
              <v-col
                offset="1"
                cols="3"
                class="text-left"
              >
                Equity available for issuance
              </v-col>
              <v-col
                cols="2"
              >
                NA
              </v-col>
              <v-col cols="2">
                NA
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ $number(item.equity_available_for_issuance.number_fully_diluted) }}
              </v-col>
              <v-col
                cols="2"
                class="pr-0"
              >
                {{ (parseFloat(item.equity_available_for_issuance.ratio_fully_diluted)*100).toFixed(2) }}%
              </v-col>
            </v-row>
          </td>
        </template>
        <!-- Total Row -->
        <template v-slot:[`body.append`]="{ headers, items }">
          <tr>
            <td
              :colspan="headers.length"
              class="px-2 footer-bg"
            >
              <v-row
                class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
              >
                <v-col
                  cols="4"
                  class="text-left pl-12"
                >
                  Total
                </v-col>
                <v-col
                  cols="2"
                >
                  <span class="mr-m2">
                    NA
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-2"
                >
                  <span>
                    NA
                  </span>
                </v-col>
                <v-col
                  cols="2"
                >
                  <span class="mr-m2">
                    {{ $number(parseFloat(getCalculatedSum(items, 'number_fully_diluted'))) }}
                  </span>
                </v-col>
                <v-col
                  cols="2"
                  class="pr-2"
                >
                  <span>
                    {{ (getCalculatedSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </common-trs-data-table>
    </div>
    <div
      v-if="!isCaptableDataAvailable"
      class="text-center py-4"
    >
      No captable data found.
    </div>
  </div>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import moment from 'moment'
  import CaptableFilters from '@/views/dashboard/captable/captableTab/components/CaptableFilters'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'

  export default {
    name: 'HolderCaptableView',

    components: {
      CaptableFilters,
      ShareTermBadge,
    },

    data: () => ({
      loading: false,
      date: moment().format('YYYY-MM-DD'),
      filterText: [],
      captableFilters: { include_financingRounds: false },
      shareTermList: [],
      warrantTermList: [],
      convertibleTermList: [],
      eipList: [],
      issuedShareTerms: [],
      issuedWarantTerms: [],
      issuedCommonShareTerms: [],
      issuedPreferredShareTerms: [],
      issuedConvertibleTerms: [],
      issuedEips: [],
      issuedAllEips: [],
      issuedSafeConvertibleTerms: [],
      issuedDebtConvertibleTerms: [],
      holderShareTerms: [],
      eipExpanded: [],
      convertiblesExpanded: [],
      summaryViewHeaders: [
        { text: 'Securities', value: 'name', sortable: true, width: '30%' },
        { text: 'Outstanding', value: 'number_outstanding', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'ratio_outstanding', sortable: false, align: 'right' },
        { text: 'Fully Diluted', value: 'number_fully_diluted', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'ratio_fully_diluted', sortable: false, align: 'right' },
      ],
      convertibleShareHeader: [
        { text: 'Securities', value: 'name', sortable: false, width: '25%' },
        { text: 'Maturity date', value: 'maturity', sortable: false, align: 'right' },
        { text: 'Principal', text2: '(in Thousands)', value: 'principal', sortable: false, align: 'right' },
        { text: 'Interest', text2: '(in Thousands)', value: 'interest', sortable: false, align: 'right' },
        { text: 'Total', text2: '(in Thousands)', value: 'total', sortable: false, align: 'right' },
      ],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      holderId () {
        return this.$store.getters['auth/holder'].id || this.$store.getters['auth/user'].id
      },
      isCaptableDataAvailable () {
        const allShareData = this.holderShareTerms?.filter((termData) => termData?.shareList?.length > 0)
        const allOptionsData = this.holderShareTerms?.filter((termData) => termData?.optionList?.length > 0)
        const allConvertiblesData = this.holderShareTerms?.filter((termData) => termData?.convertibleList?.length > 0)
        return allShareData?.length > 0 || allOptionsData?.length > 0 || allConvertiblesData?.length > 0
      },
    },

    watch: {
      captableFilters: {
        async handler (val) {
          if (val) {
            this.date = val.date
            this.holderShareTerms = []
            this.loading = true
            await this.getCaptableSummary()
            this.loading = false
          }
        },
        immediate: true,
        deep: true,
      },
    },

    async mounted () {
      this.loading = true
      await this.getAllShareTerms()
      await this.getAllWarrantTerms()
      await this.getAllConvertibleTerms()
      await this.getAllEips()
      await this.getCaptableSummary()
      this.loading = false
    },

    methods: {
      async getAllShareTerms () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTermList = resp.data.share_terms
        } catch (e) {
          this.loading = false
        }
      },
      async getAllWarrantTerms () {
        try {
          const resp = await captableService.getWarrantTermList(this.captableId)
          this.warrantTermList = resp.data.warrant_terms
        } catch (e) {
          this.loading = false
        }
      },
      async getAllConvertibleTerms () {
        try {
          const resp = await captableService.getConvertibleTermList(this.captableId)
          this.convertibleTermList = resp.data.convertible_terms
        } catch (e) {
          this.loading = false
        }
      },
      async getAllEips () {
        try {
          const resp = await captableService.getEipList(this.captableId)
          this.eipList = resp.data.equity_incentive_plans
        } catch (e) {
          this.loading = false
        }
      },
      async getCaptableSummary () {
        try {
          this.date = this.date ? this.date : moment().format('YYYY-MM-DD')
          const resp = await captableService.getCaptableSummaryData(this.captableId, this.date, false)
          this.issuedShareTerms = await this.getUpdatedTermData(resp.data.equity_summary.financing_rounds, 'share_terms')
          this.issuedWarantTerms = await this.getUpdatedTermData(resp.data.equity_summary.financing_rounds, 'warrant_terms')
          this.issuedConvertibleTerms = await this.getUpdatedTermData(resp.data.convertible_summary.financing_rounds, 'convertible_terms')
          this.issuedEips = await this.getUpdatedTermData([resp.data.equity_summary.equity_incentive_plans], 'equity_incentive_plans')

          this.issuedShareTerms = [...this.issuedShareTerms, ...resp.data.equity_summary.others.share_terms]
          this.issuedWarantTerms = [...this.issuedWarantTerms, ...resp.data.equity_summary.others.warrant_terms]

          this.issuedCommonShareTerms = await this.getCurrentHolderData(this.CONSTS.SHARE_TERM_TYPES.COMMON)
          this.issuedPreferredShareTerms = await this.getCurrentHolderData(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)

          this.issuedAllEips = await this.getCurrentHolderOptions()
          this.issuedSafeConvertibleTerms = await this.getCurrentHolderConvertiblesByType(this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE)
          this.issuedDebtConvertibleTerms = await this.getCurrentHolderConvertiblesByType(this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT)

          this.holderShareTerms = [{
            title: 'Common Shares',
            shareList: this.issuedCommonShareTerms,
          }, {
            title: 'Preferred Shares',
            shareList: this.issuedPreferredShareTerms,
          }, {
            title: 'Convertibles',
            convertibleList: [...this.issuedSafeConvertibleTerms, ...this.issuedDebtConvertibleTerms],
          }, {
            title: 'EIPs',
            optionList: this.issuedAllEips,
          }]
        } catch (e) {
          this.loading = false
        }
      },
      navigateToDetailsPage (id, type) {
        this.$router.push({ name: 'Holder.Holdings', params: { id: id, type: type } })
      },
      getCurrentHolderData (type) {
        let holderSharesData = []
        let holderWarrantsData = []
        const blankIndxList = []
        let termList = JSON.parse(JSON.stringify(this.getMergedSharesWarrants(type))) || []
        let i = 0
        termList.forEach((term) => {
          holderSharesData = this.getHolderData(term.shares)
          holderWarrantsData = this.getHolderData(term.warrants)
          term.shares = this.getMergedHolderShares(holderSharesData)
          term.warrants = this.getMergedHolderShares(holderWarrantsData)
          if (holderSharesData.length === 0 && holderWarrantsData.length === 0) {
            blankIndxList.push(i)
          }
          i++
        })
        if (blankIndxList && blankIndxList.length > 0) {
          blankIndxList.reverse().forEach((idx) => {
            termList.splice(idx, 1)
          })
        }
        termList = this.getHoldersCalculatedData(termList)
        termList.sort((a, b) => {
          return new Date(a.board_approved_on) - new Date(b.board_approved_on)
        })
        return termList
      },
      getCurrentHolderOptions () {
        let holderOptions = []
        this.issuedEips.forEach((eip) => {
          const currentHolderData = this.getHolderData(eip.options)
          holderOptions = [...currentHolderData, ...holderOptions]
          eip.options = holderOptions
        })
        return holderOptions?.length > 0 ? this.issuedEips : []
      },
      getCurrentHolderConvertiblesByType (type) {
        const holderConvertibles = []
        this.updateConvertibleTerms()
        const currentConvertibleTerms = this.issuedConvertibleTerms.filter((term) => term.type === type) || []
        currentConvertibleTerms.forEach((term) => {
          const currentHolderData = this.getHolderData(term.convertibles)
          if (currentHolderData?.length > 0) {
            term.convertibles = currentHolderData
            holderConvertibles.push(term)
          }
        })
        return holderConvertibles?.length > 0 ? holderConvertibles : []
      },
      getHolderData (list) {
        return list?.filter((item) => {
          if (this.holderId.includes(item?.holder?.id)) {
            return item
          }
        }) || []
      },
      getHoldersCalculatedData (termList) {
        termList.forEach((term) => {
          term.number_outstanding = this.$number(this.getCalculatedSum(term.shares, 'number_outstanding'))
          term.number_fully_diluted = this.$number(this.getCalculatedSum(term.shares, 'number_fully_diluted') + this.getCalculatedSum(term.warrants, 'number_fully_diluted'))
          term.ratio_outstanding = (this.getCalculatedSum(term.shares, 'ratio_outstanding') * 100).toFixed(2) + '%'
          term.ratio_fully_diluted = ((this.getCalculatedSum(term?.shares, 'ratio_fully_diluted') + this.getCalculatedSum(term?.warrants, 'ratio_fully_diluted')) * 100).toFixed(2) + '%'
        })
        return termList
      },
      getMergedSharesWarrants (type) {
        const consolidatedTerms = []
        let isMatched = true
        let isShareMatched = true
        this.issuedShareTerms.forEach((sTerm) => {
          let linkedShareTerm = consolidatedTerms.find((term) => term.id === sTerm.share_terms_id)
          if (!linkedShareTerm) {
            linkedShareTerm = JSON.parse(JSON.stringify(this.shareTermList.find((term) => term.id === sTerm.share_terms_id)))
            linkedShareTerm.shares = []
            linkedShareTerm.warrants = []
            isShareMatched = false
          }
          const currentShares = sTerm.shares || []
          const currentWarrants = sTerm.warrants || []
          linkedShareTerm.shares = [...linkedShareTerm.shares, ...currentShares]
          linkedShareTerm.warrants = [...linkedShareTerm.warrants, ...currentWarrants]
          if (!isShareMatched) {
            consolidatedTerms.push(linkedShareTerm)
            isShareMatched = true
          }
        })
        this.issuedWarantTerms.forEach((wTerm) => {
          const linkedWarrantTerm = this.warrantTermList.find((term) => term.id === wTerm.warrant_terms_id)
          let linkedShareTerm = consolidatedTerms.find((term) => term.id === linkedWarrantTerm.share_terms_id)
          if (!linkedShareTerm) {
            linkedShareTerm = JSON.parse(JSON.stringify(this.shareTermList.find((term) => term.id === linkedWarrantTerm.share_terms_id)))
            linkedShareTerm.warrants = []
            isMatched = false
          }
          linkedShareTerm.warrant_name = linkedWarrantTerm.name
          linkedShareTerm.warrant_terms_id = linkedWarrantTerm.id
          linkedShareTerm.warrants = [...linkedShareTerm.warrants, ...wTerm.warrants]
          if (!isMatched) {
            consolidatedTerms.push(linkedShareTerm)
            isMatched = true
          }
        })
        return consolidatedTerms.filter((term) => term.type === type)
      },
      getMergedHolderShares (holdersShares) {
        if (holdersShares?.length < 2) {
          return holdersShares
        } else {
          const updatedShares = holdersShares[0]
          updatedShares.number_outstanding = this.getCalculatedSum(holdersShares, 'number_outstanding')
          updatedShares.number_fully_diluted = this.getCalculatedSum(holdersShares, 'number_fully_diluted')
          updatedShares.ratio_outstanding = this.getCalculatedSum(holdersShares, 'ratio_outstanding')
          updatedShares.ratio_fully_diluted = this.getCalculatedSum(holdersShares, 'ratio_fully_diluted')
          return [updatedShares]
        }
      },
      getUpdatedTermData (roundList, key) {
        let filteredTerms = []
        const filteredRounds = roundList.filter((round) => round[key].length > 0)
        filteredRounds.forEach((round) => {
          filteredTerms = [...filteredTerms, ...round[key]]
        })
        return filteredTerms
      },
      updateAsofDate (value) {
        this.captableFilters = Object.assign({}, value) || ''
      },
      getCalculatedSum (list, key) {
        let total = 0
        if (list?.length > 0) {
          total = list.reduce((accumulator, currentValue) => {
            (total += parseFloat(currentValue[key]))
            return total
          }, 0)
        }
        return total
      },
      calculateShareSum (list, key) {
        let total = 0
        list.forEach((item) => {
          if (!item.warrants || (key === 'number_outstanding' || key === 'ratio_outstanding')) {
            total += this.getCalculatedSum(item.shares, key)
          } else if (key !== 'number_outstanding' && key !== 'ratio_outstanding') {
            if (item.shares) {
              total += this.getCalculatedSum([...item.shares, ...item.warrants], key)
            } else {
              total += this.getCalculatedSum(item.warrants, key)
            }
          }
        })
        return total
      },
      getAmountInThousands (amount) {
        if (amount > 0) {
          amount = amount / 1000
        }
        return amount
      },
      updateConvertibleTerms () {
        this.issuedConvertibleTerms.forEach((term) => {
          const termDetails = this.convertibleTermList.find((item) => item.id === term.id)
          term.type = termDetails?.convertible_type
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .trs-table-container-card {
    tr {
      cursor: pointer;
    }
  }
  ::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
  }
  .display-inline {
    display: inline-block;
  }
</style>
