<template>
  <common-trs-page-container>
    <common-app-page-header-v2
      title="Cap Table Summary"
      :padding="0"
      :has-back-button="!isDirectLogin"
    />
    <holder-captable-view />
  </common-trs-page-container>
</template>

<script>
  import HolderCaptableView from './components/HolderCaptableView'

  export default {
    name: 'HoldersLandingPage',

    components: {
      HolderCaptableView,
    },

    computed: {
      isDirectLogin () {
        return this.$store.getters['auth/isDirectLogin']
      },
    },
  }
</script>
