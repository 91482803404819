var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('captable-filters',{attrs:{"is-summary-view":false,"is-holder":true},on:{"input":_vm.updateAsofDate},model:{value:(_vm.captableFilters),callback:function ($$v) {_vm.captableFilters=$$v},expression:"captableFilters"}}),_vm._l((_vm.holderShareTerms),function(termData,key){return _c('div',{key:key,staticClass:"mt-4"},[((termData.shareList && termData.shareList.length > 0) || (termData.optionList && termData.optionList.length > 0) || (termData.convertibleList && termData.convertibleList.length > 0))?_c('div',{staticClass:"trsText--text text-h4 font-weight-medium"},[_vm._v(" "+_vm._s(termData.title)+" ")]):_vm._e(),(termData.shareList && termData.shareList.length > 0)?_c('common-trs-data-table',{staticClass:"trs-table-container-card",attrs:{"headers":_vm.summaryViewHeaders,"items":termData.shareList,"hide-default-footer":"","disable-pagination":"","item-key":"id","show-expand":"","expanded":termData.expanded},on:{"update:expanded":function($event){return _vm.$set(termData, "expanded", $event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._l((item.shares),function(obj){return _c('v-row',{key:obj.id,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.id, item.type)}}},[_c('v-col',{staticClass:"text-left pl-10",attrs:{"cols":"4"}},[_c('share-term-badge',{staticClass:"display-inline",attrs:{"type":item.type,"show-single":true}}),_c('span',[_vm._v(_vm._s(item.name))])],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_outstanding))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_outstanding*100).toFixed(2))+"% ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{staticClass:"pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)}),_vm._l((item.warrants),function(obj){return _c('v-row',{key:obj.id,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.warrant_terms_id, _vm.CONSTS.SHARE_TERM_TYPES.WARRANT)}}},[_c('v-col',{staticClass:"text-left pl-10",attrs:{"cols":"4"}},[_c('share-term-badge',{staticClass:"display-inline",attrs:{"type":_vm.CONSTS.SHARE_TERM_TYPES.WARRANT,"show-single":true}}),_c('span',[_vm._v(_vm._s(item.warrant_name))])],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{staticClass:"pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)})],2)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateShareSum(items, 'number_outstanding'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateShareSum(items, 'ratio_outstanding') * 100).toFixed(2))+" % ")])]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateShareSum(items, 'number_fully_diluted'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateShareSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2))+" % ")])])],1)],1)])]}}],null,true)}):_vm._e(),(termData.convertibleList && termData.convertibleList.length > 0)?_c('common-trs-data-table',{staticClass:"trs-table-container-card pa-5",attrs:{"headers":_vm.convertibleShareHeader,"items":termData.convertibleList,"show-expand":"","hide-default-footer":"","expanded":_vm.convertiblesExpanded,"disable-pagination":"","item-key":"id"},on:{"update:expanded":function($event){_vm.convertiblesExpanded=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.maturity",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.principal",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.interest",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"item.principal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(parseFloat(item.principal))))+" ")])]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(parseFloat(item.interest))))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(parseFloat(item.total))))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":headers.length}},_vm._l((item.convertibles),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.id, _vm.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE, obj.holder)}}},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_c('span',[_c('share-term-badge',{staticClass:"display-inline",attrs:{"type":item.type,"show-single":true}})],1),_c('span',[_vm._v(_vm._s(item.name))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$date(obj.matures_on))+" ")]),_c('v-col',{staticClass:"pr-6",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.principal)))+" ")]),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.interest)))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.total)))+" ")])],1)}),1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"pr-7",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(_vm.getCalculatedSum(items, 'principal', true))))+" ")])]),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(_vm.getCalculatedSum(items, 'interest', true))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(_vm.getCalculatedSum(items, 'total', true))))+" ")])])],1)],1)])]}}],null,true)}):_vm._e(),(termData.optionList && termData.optionList.length > 0)?_c('common-trs-data-table',{staticClass:"trs-table-container-card pa-5",attrs:{"headers":_vm.summaryViewHeaders,"items":termData.optionList,"show-expand":"","hide-default-footer":"","expanded":_vm.eipExpanded,"disable-pagination":""},on:{"update:expanded":function($event){_vm.eipExpanded=$event}},scopedSlots:_vm._u([{key:"item.number_outstanding",fn:function(ref){return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" NA ")])]}},{key:"item.ratio_outstanding",fn:function(ref){return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" NA ")])]}},{key:"item.number_fully_diluted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$number(item.number_fully_diluted))+" ")])]}},{key:"item.ratio_fully_diluted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s((parseFloat(item.ratio_fully_diluted) * 100 ).toFixed(2))+" % ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":headers.length}},[_vm._l((item.options),function(option,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"},on:{"click":function($event){return _vm.navigateToDetailsPage(item.id, _vm.CONSTS.SHARE_TERM_TYPES.OPTION)}}},[_c('share-term-badge',{staticClass:"display-inline",attrs:{"type":_vm.CONSTS.OPTIONS_TYPE.ISO,"show-single":true}}),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(option.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((parseFloat(option.ratio_fully_diluted)*100).toFixed(2))+"% ")])],1)}),_c('v-row',{staticClass:"trsText--text text-body-2 text-right font-weight-bold"},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_vm._v(" Equity available for issuance ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.equity_available_for_issuance.number_fully_diluted))+" ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((parseFloat(item.equity_available_for_issuance.ratio_fully_diluted)*100).toFixed(2))+"% ")])],1)],2)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" NA ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" NA ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.getCalculatedSum(items, 'number_fully_diluted'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.getCalculatedSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2))+" % ")])])],1)],1)])]}}],null,true)}):_vm._e()],1)}),(!_vm.isCaptableDataAvailable)?_c('div',{staticClass:"text-center py-4"},[_vm._v(" No captable data found. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }